
  import SideBarMenu from "./SideBar/SideBarMenu";
export default {
  components:{
    SideBarMenu
  },
props:{
  translucentWhite:{
    type: Boolean,
    default: false
  }
},
  data() {
    return {
      active: false,
      scrolled: false,
      url: null,
      checkHeader: 'default',
      isShare: false,
      isFilter: false,
    }
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    checkHeaderType() {
      const fullPath = this.$route.fullPath
      const params = fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/')
      if ($nuxt.$route.name) {
        //  if ($nuxt.$route.name != 'index') {
        //   this.checkHeader = "back";
        // }
        // else if($nuxt.$route.name == 'index'){
        //   this.checkHeader = "home";
        // }
        // else {
        //   this.checkHeader = "default";
        // }
        if(params && params.length < 2){
          this.checkHeader = "home";
        }
        else{
          this.checkHeader = "back";
        }
        if ($nuxt.$route.name == 'activities' || $nuxt.$route.name == 'rentals' || $nuxt.$route.name == 'places' || $nuxt.$route.name == 'index' || $nuxt.$route.name == 'packages' || $nuxt.$route.name == 'experiences-category' || $nuxt.$route.name == 'explore-category') {
          this.isShare = true;
        }
        else{
          this.isShare = false;
        }
      }
    },
    hasHistory() {
      return this.$nuxt.context.from;
    },
    call() {
        this.active = !this.active;
        let self = this;
        window.addEventListener('click', function (e) {
          // close dropdown when clicked outside
          if (!self.$el.contains(e.target)) {
            self.active = false
          }
        })
      },
      activeClose(value) {
        this.active = value;
      }

  },
  created() {
    if (process.browser)
      window.addEventListener('scroll', this.handleScroll);
  },

  watch: {
    $route(to, from) {
      this.url = window.location.href;
      const fullPath = this.$route.fullPath
      const params = fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/')
      if (to && to.name) {
        //  if (to.name != 'index') {
        //   this.checkHeader = "back";
        // }
        // else if(to.name == 'index'){
        //   this.checkHeader = "home";
        // }
        // else {
        //   this.checkHeader = "default";
        // }
        if(params && params.length < 2){
          this.checkHeader = "home";
        }
        else{
          this.checkHeader = "back";
        }
        if (to.name == 'activities' || to.name == 'rentals' || to.name == 'places' || to.name == 'index' || to.name == 'packages' || to.name == 'experiences-category' || to.name == 'explore-category') {
          this.isShare = true;
        }
        else{
          this.isShare = false;
        }
      }
    }
  },

  mounted() {
    if (process.browser)
    this.url = window.location.href;
    this.checkHeaderType();
  },
  updated() {
    if (process.browser)
    this.url = window.location.href;
    this.checkHeaderType();
  },


}
